import { Children, useState, useEffect } from "react";
import { Paper, Typography, Grid, Tooltip } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { getDate, getHours, getMinutes, getMonth } from "date-fns";
import sharedService from "../../../../../Services/SharedService";

// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import LineChart from "../../../../Components/Chart/Component/LineChart";
import BarChart from "../../../../Components/Chart/Component/BarChart";
import WaterLevelView from "../../../../Components/Chart/Component/WaterLevelView";
import MultiLineChart from "../../../../Components/Chart/Component/MultiLineChart";

import { calculateQS as CalculateFlowSpin } from "../../../../Components/Chart/Component/chart_data";
import { CalculateFlow as CalculateFlowDrain } from "../../../../Components/Interpolation/drain";
import image from "../../../../../Asset/water_proof2/daton1.png";
import { BuildChartData, adjustRAData, TYPE_CHART } from "../../../../Components/Chart/Component/chart_data";
import { ID_GROUPS } from "../../../../../Utils/const";
import { NAMED_COLORS } from "../../../../Components/App/Utils/CustomChart";

const Chart = (props) => {
    const [dataWaterLevelView, setDataWaterLevelView] = useState()
    const [charts, setCharts] = useState();
    const [slideIndex, setSlideIndex] = useState(+1);

    const checkKG = window.localStorage.getItem('group_id') === ID_GROUPS.KG

    useEffect(async () => {
        let z; // Ztl
        let dr; // độ mở cống
        let q; // lưu lượng
        const groupRecord = await (await sharedService.listRecordBoxGroup()).data;

        if (checkKG) {
            groupRecord.map(g => {
                //fake data
                if(g.box.id ===  "B5R6ZA0U" || g.box.id === "JD0YTE49") {
                    const rc =  g.records.find(r => +r.t === 1742436000)
                    if(rc) rc.RA = 40
                }
                //
                g.records = g.records.filter(r => getMinutes(r.t * 1000) === 0 || getMinutes(r.t * 1000) === 30)
                return g
            });
            let recordsWAU = groupRecord.find(g => g.metrics.includes("WAU")).records;
            groupRecord.map(g => {
                if(g.box.id === "3KVAXM37" || g.box.id === "Z00PSBWY") {
                    g.records.map(d => {
                        recordsWAU.find(w => {
                            if (getDate(w.t * 1000) === getDate(d.t * 1000) && getMonth(w.t * 1000) === getMonth(d.t * 1000)) {
                                if (getHours(w.t * 1000) === getHours(d.t * 1000)) d.WAU = w.WAU
                                else if (getHours(d.t * 1000) === getHours(w.t * 1000) - 1) d.WAU = w.WAU
                                else if (getHours(d.t * 1000) === getHours(w.t * 1000) + 1) d.WAU = w.WAU
                            }
                        })
                        return d;
                    });
                    g.box.metrics.push({ name: 'Mực nước', code: 'WAU', metric: 'WAU' })
                }
            })
        }
        // Thông số hiện tại
        groupRecord.forEach(g => {
            if (g.count === 0) return;
            if (g.box.id === 'WK34ZRT9') {
                const record = g.records[0]
                z = record?.WAU
                q = CalculateFlowSpin(record, z)
            }
            if (g.box.id === 'R8ZLNSJX') {
                dr = g.records[0]?.DR1 / 1000
            }

        })
        const dataWLV = { z, q, q_of: CalculateFlowDrain(dr, z) || 0 }
        setDataWaterLevelView(dataWLV)
        //////
        const result = groupRecord.map(g => {
            if (g.count === 0) return
            const chart = BuildChartData(g.records, g.box, z)
            return chart
        })

        const docs = adjustRAData(result.filter(r => r))
        if (checkKG) {
            // Thêm đường hiển thị mực nước thượng lưu vào 4 biểu đồ khác dc yêu cầu
            const dataWAU = docs.find(d => d.metric === 'WA' && d?.datasets[0]?.code === 'WAU')
            if(dataWAU) {
            dataWAU.datasets[0].borderColor = NAMED_COLORS[5]
            dataWAU.datasets[0].backgroundColor = NAMED_COLORS[5]
                docs.map(d => {
                    if(['SPMXV9A0', 'WU589RSL', 'YKEZCC6N', 'QYOV7RB9'].includes(d.box_id)) {
                        d.datasets.push(dataWAU.datasets[0])
                    }
                })
            }
            setCharts(docs.filter(d => d.type))
        }   
        else setCharts(docs)
    }, [])

    const handleSlide = (e) => {
        const { activeIndex } = e;
        setSlideIndex(activeIndex + 1);
    }


    const handleFullScreen = () => {
        const img = document.getElementById("chart-slide");
        img.requestFullscreen();
    }

    return (
        <>
            <Paper
                id="chart-slide"
                className="Chart-Right"
                elevation={8}
                sx={{
                    boxShadow: 'none',
                    border: '1.5px solid',
                    borderRight: 'none',
                    color: ' #0000001f',
                    borderRadius: '5px'
                }}
            >
                <div className="middle top">
                    <Grid container>
                        <Grid item xs={12} className="middle">
                            <Typography
                                className="title"
                                sx={{ fontSize: { xs: '16px', lg: '18px' } }}
                            >
                                Dữ liệu biểu đồ
                                <Tooltip title="Toàn màn hình" className="btn-fs">
                                    <FullscreenIcon className="fontsz-30" onClick={handleFullScreen} />
                                </Tooltip>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className="content">
                    <Swiper
                        className="content-carousel"
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={50}
                        slidesPerView={1}
                        // navigation
                        pagination={{ clickable: true }}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        // scrollbar={{ draggable: true }}
                        // onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={handleSlide}
                    >
                        {charts && Children.toArray(charts.map(t => {
                            if (t.metric === 'WAU') return <SwiperSlide onDoubleClick={handleFullScreen} className='pointer'><WaterLevelView box_id={t.box_id} data={dataWaterLevelView} /></SwiperSlide>
                            if (t.type === 'line') return <SwiperSlide onDoubleClick={handleFullScreen} className='pointer'><LineChart max={checkKG && t.metric === 'WA' ? 60 : null} min={checkKG && t.metric === 'WA' ? 0 : ''} data={t} /></SwiperSlide>
                            if (t.type === 'multiLine') return <SwiperSlide onDoubleClick={handleFullScreen} className='pointer'><MultiLineChart data={t} /></SwiperSlide>
                            if (t.type === 'bar') return <SwiperSlide onDoubleClick={handleFullScreen} className='pointer'><BarChart data={t} /></SwiperSlide>
                            else return <SwiperSlide onDoubleClick={handleFullScreen} className='pointer'><img src={image} className="width-percent-100" alt="" /></SwiperSlide>
                        }))}
                    </Swiper>
                </div>
                <div className="bot">
                    <Typography className="fontsz-14 end pr-17">
                        <>Hiển thị {slideIndex}/ <b>{charts?.length}</b> biểu đồ</>
                    </Typography>
                </div>
            </Paper>
        </>
    )
}

export default Chart;